import request from '@/utils/request'
import Api from '@/api/base/api-config'

export const login = (data) => {
  return request({
    method: 'POST',
    url: Api.user.login,
    data
  })
}

// 获取account_no和token
export const getToken = (params) => {
  return request({
    method: 'GET',
    url: `${Api.user.getToken}?uid=${params.uid}&access_token=${params.access_token}`
  })
}
// 根据用户userid获取token以及account_no
export const getTokenAndAccoun = (params) => {
  return request({
    method: 'GET',
    url: `${Api.user.getTokenAndAccoun}?userid=${params.userId}`
  })
}
// 获取用户信息
export const getUserInfo = (userId) => {
  return request({
    method: 'GET',
    url: `${Api.user.getUserInfo}/${userId}`
  })
}