import { login, getUserInfo } from '@/api/user'
import { getBaseaData } from '@/api/testdrive'
import { getStoreList, getRoleAuthority, getUserListAll } from '@/api/system'
import storage from '@/utils/storage'
import {orderBy} from 'lodash-es'

export function getInfoByAccount (account_no) {
  const info = account_no.split('_')
  const orgInfo = info[1].split('.')
  const PathCode = orgInfo[0].split('*')
  const roleCode = info[info.length - 1]
  const orgCode = PathCode[PathCode.length - 1]

  // 用户UID
  storage.set('userId', info[0])
  // 角色Code
  storage.set('roleCode', info[info.length - 1])
  // Path
  storage.set('Path', orgInfo[0])
  // orgType
  storage.set('orgType', orgInfo[1])
  // orgCode
  storage.set('orgCode', PathCode[PathCode.length - 1])

  getChannel()

  return Promise
    .all([
      getUser(info[0]),
      getStoreAllList('HQ0001').then(() => getStoreUserList(orgCode)),
      getBaseData(),
      getAuthority(roleCode),
      getMakeEmpList(roleCode, orgCode)
    ])
}
// 获取用户信息
function getUser (userId) {
  return getUserInfo(userId).then(res => {
    if (res.success) {
      storage.set('userInfo', res.data)
    }
  })
}

// 如果当前角色是出行主管，则获取当前主管下的出行顾问，为了后续做数据隔离
function getMakeEmpList (roleCode, orgCode) {
  if (roleCode === 'RE006') {
    return getUserListAll(orgCode).then(res => {
      if (res.success) {
        storage.set('makeEmpList', res.data)
      }
    })
  }
  return Promise.resolve()
}

// 获取资源权限
function getAuthority (roleCode) {
  return getRoleAuthority(roleCode).then(res => {
    if (res.success) {
      const authorized = res.data.map(item => item.code)
      storage.set('authorized', authorized)
    }
  })
}

// 获取全部门店
function getStoreAllList (orgCode) {
  const params = { orgCode }
  return getStoreList(params).then(res => {
    if (res.success) {
      storage.set('saleDlr', res.data)
    }
  })
}
// 获取当前用户所属的门店
function getStoreUserList (orgCode) {
  const params = { orgCode }
  return getStoreList(params).then(res => {
    if (res.success) {
      storage.set('userStore', res.data)
    }
  })
}
// 获取页面基础数据
function getBaseData () {
  if (!storage.get('baseData')) {
    return getBaseaData().then(res => {
      if (res.success) {
        const baseData = {}
        res.data.forEach((e) => {
          if (!(e.dataCode in baseData)) {
            baseData[e.dataCode] = []
          }
          baseData[e.dataCode].push(e)
        })
        baseData.leads_score=orderBy(baseData.leads_score,['value'],['desc'])
        storage.set('baseData', baseData)
      }
    })
  }
  return Promise.resolve()
}

// 获取渠道码
function getChannel () {
  let channel
  try {
    channel = window.api.systemType === 'ios' ? 'SAI' : 'SAA'
  } catch (e) {
    channel = 'SAA'
  }
  storage.set('channel', channel)
}