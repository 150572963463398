/*
 * @Author: your name
 * @Date: 2021-03-21 10:42:33
 * @LastEditTime: 2021-05-20 11:21:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\api\system\index.js
 */
import request from '@/utils/request'
import Api from '@/api/base/api-config'

// 获取门店
export const getStoreList = (params) => {
  return request({
    method: 'GET',
    url: Api.system.getStoreList,
    params
  })
}

// 用户组织角色管理/用户组织角色列表
export const orgRoleList = (idmUserId) => {
  return request({
    method: 'get',
    url: `${Api.system.orgRoleList}/${idmUserId}/orgRoleList`
  })
}
// 切换登陆的用户角色或组织
export const changeRole = params => {
  return request({
    url: `${Api.system.changeRole}?accountNo=${params}`,
    method: 'get'
  })
}
// 退出登陆
export const loginOut = () => {
  return request({
    url: `${Api.system.loginOut}`,
    method: 'get'
  })
}
// 角色列表
export const getUserListAll = params => {
  return request({
    method: 'get',
    url: `${Api.system.userListAll}?orgCode=${params}`
  })
}
// 门店
export const storeList = (params) => {
  return request({
    method: 'GET',
    url: Api.system.storeList,
    params: params
  })
}

// 权限列表
export const getRoleAuthority = (roleCode) => {
  return request({
    method: 'get',
    url: `${Api.system.roleAuthority}/${roleCode}/authoritylist`
  })
}